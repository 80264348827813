import { isAxiosError } from 'axios';
import type { Form } from '#ui/types';
import type { FormError } from '#ui/types/form';
import type { BackendErrors } from '~/types';

export const setBackendErrors = <T = any>(form: Form<T>, errors: BackendErrors) => {
  const formErrors: FormError[] = [];
  for (const path in errors) {
    formErrors.push({ path, message: errors[path][0] });
  }
  form.setErrors(formErrors);
};

export const isBackendError = (e: any) => isAxiosError(e) && e.response && e.response.status >= 400;

export const isBackendValidationError = (e: any) => isAxiosError(e) && e.response?.status === 422;
